@use "../config/" as *;

.waf-static-content {
    @extend %p-x-half;
    @extend %m-y-zero;
    .waf-body {
        @include flex-config(flex,column,flex-end,null);
    }
}
.waf-mission {
    .waf-head {
        @extend %d-none;
    }
    .tabs {
        margin: 0;
    }
    .mission {
        @extend %m-b-three-space;
        .text {
            @extend %capitalize;
            @extend %c-blue-1-10;
            @extend %font-14-pr;
            @extend %m-b-half;
        }
        .title {
            @extend %c-blue-10;
            @extend %font-24-pb;
        }
        &-list {
            ul {
                list-style: disc;
                @extend %m-l-two-space;
            }
            .text {
                @extend %c-black-6;
                @extend %font-14-pr;
            }
        }
    }
}
.waf-contact {
    padding: 0 0 var(--three-space);
    @extend %m-x-half-neg;
    @extend %m-y-zero;
    @extend %c-blue-bg-10;
    .form {
        &-title {
            @extend %m-b-two-space;
            .title {
                line-height: 1;
                @extend %uppercase;
                @extend %c-white-10;
                @extend %font-42-sb;
            }
        }
        &-address {
            margin-bottom: 4.2rem;
            @extend %c-white-10;
            @extend %font-16-pr;
            &-value {
                font: normal 700 1.6rem/1.5 $font-pb;
                @extend %m-t-quarter;
                @extend %d-block;
            }
        }
        &-group {
            @extend %m-b-one-n-half;
        }
        &-element {
            textarea {
                border: .1rem solid hsla(var(--hsl-c-white)/.2);
                resize: vertical;
                @extend %p-full;
                @extend %d-block;
                @extend %c-white-bg-1;
                @extend %half-radius;
            }
        }
        &-footer {
            .btn-send {
                height: 4rem;
                @extend %w-100;
            }
            .btn-text {
                @extend %capitalize;
                @extend %font-16-pb;
            }
        }
        &-error-msg {
            @extend %d-none;
            &:not(:empty) {
                @extend %m-t-half;
                @extend %d-block;
                @extend %c-error-10;
            }
        }
        &-response-text {
            @extend %c-orange-10;
        } 
    }
    .waf-select-box {
        @extend %relative;
        &.active {
            .select-box-wrap {
                @include dropdown(open);
            }
            .selected-label::after {
                transform: rotate(180deg);
            }
        }
        .sub-title {
            @extend %c-white-6;
            @extend %font-12;
        }
        .selected-label {
            min-height: 4.6rem;
            border-bottom: .1rem solid hsla(var(--hsl-c-white)/.8);
            overflow-wrap: anywhere;
            @extend %p-y-half;
            @extend %p-x-full;
            @extend %flex-sb-c;
            @extend %c-white-10;
            @extend %font-18-pr;
            @extend %gap-half;
            &::after {
                content: "\e806";
                font: 2.4rem/1 $font-icon;
            }
        }
        .select-box-wrap {
            border-radius: var(--half-radius);
            box-shadow: 0 .4rem 1.6rem hsl(var(--hsl-c-black)/.1);
            overflow: visible;
            @include position(calc(100% + var(--quarter-space)), null, null, 0);
            @include dropdown(close);
            @extend %w-100;
            @extend %c-white-bg-10;
        }
        .select-list {
            max-height: 17rem;
            padding: var(--half-space) var(--full-space);
            overflow-y: auto;
            @include custom-scroll();
        }
        .list-item {
            padding-block: var(--quarter-space);
            &:not(:last-child) {
                border-bottom: .1rem solid hsla(var(--hsl-c-blue)/.2);
            }
        }
        button {
            @extend %c-blue-10;
            @extend %font-14;     
        }
    }
    .waf-head {
        @extend %relative;
        &:before {
            content: '';
            @extend %position-b-l;
            @extend %h-100;
            @extend %w-100;
            @include linear-gradient(179.96deg, hsla(var(--hsl-c-blue)/0) 0.04%, hsla(var(--hsl-c-blue)/1) 100%);
        }
        .contact-image {
            aspect-ratio: 2/3;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .waf-body {
        margin-top: -15rem;
        @extend %relative;
        @extend %p-x-full;
    }
}
.waf-sponsor {
    @extend %p-x-half;
    @extend %m-b-two-space;
    @extend %p-y-zero;
    .title {
        @extend %m-b-two-space;
        @extend %font-42-sb;
        @extend %c-blue-10;
    }
    .sponsor{
        &-list {
            row-gap: var(--one-n-half-space);
            flex-wrap: wrap;
            @extend %flex;
        }
        &-item {
            border: .1rem solid var(--hsl-c-blue-light);
            height: 20rem;
            @extend %w-100;
            @extend %flex-c-c;
            @extend %half-radius;
            @extend %c-light-grey-1-bg-4;
            img {
                width: 12rem;
                object-fit: contain;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-static-content {
        .waf-body {
            flex-direction: row;
        }
        .tabs {
            width: 30rem;
        }
        .tabs-data {
            width: calc(100% - 30rem);
        }
    }
    .waf-contact {
        padding-bottom: 0;
        .layout-wrapper {
            display: flex;
            margin-left: unset;
            margin-right: auto;
        }
        .waf-head {
            width: 50%;
            &::before {
                left: unset;
                right: 0;
                @include linear-gradient(88deg, hsla(var(--hsl-c-blue)/0) 0.04%, hsla(var(--hsl-c-blue)/1) 85%);
            }
            .contact-image {
                aspect-ratio: unset;
                height: 70rem;
            }
        }
        .form {
            &-title {
                .title {
                    font-size: 5.4rem;
                }
            }
            &-footer {
                .btn-send {
                    width: 13rem;
                }
            }
            &-group-inline {
                display: flex;
                gap: var(--two-space);
                .form-group {
                    width: calc(50% - var(--two-space));
                }
            }
            &-response-text {
                font-size: 1.6rem;
            }
        }
        .waf-body {
            margin-top: 10rem;
            padding-inline: 0;
            width: 50%;
        }
    }
    .waf-sponsor {
        .title {
            font-size: 6.4rem;
        }
        .sponsor{
            &-list {
                gap: var(--one-n-half-space);
            }
            &-item {
                height: 36rem;
                width: calc(50% - var(--one-n-half-space));
                img {
                    width: 18rem;
                }
            }
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-contact {
        .waf-head {
            .contact-image {
                height: 86rem;
            }
        }
        .form {
            &-title {
                .title {
                    font-size: 6.4rem;
                }
            }
        }
        .waf-body {
            margin-top: 12rem;
            padding-inline: 7rem 0;
        }
    }
}